.section {
  width: 100%;
  padding: 16px;
  padding-top: 100px;
}

.section-container {
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
}

.section-container-top {
  text-align: center;
  max-width: 495px;
  margin: 0 auto;
}

.section-container-top h1 {
  font-family: PolySans;
  font-size: 48px;
  font-weight: 600;
  line-height: 55.2px;
  letter-spacing: -0.01em;
  text-align: center;
  margin-bottom: 18px;
}

.section-container-top p {
  font-family: PolySans;
  font-size: 20px;
  font-weight: 300;
  line-height: 32.2px;
  text-align: center;
}

.section-container-middle {
  margin-top: 50px;
  display: flex;
  max-width: 1040px;
  gap: 25px;
}

.section-container-bottom {
  margin-top: 28px;
  background-color: #f8f8f8;
  border-radius: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-end;
  justify-content: center;
  height: 333px;
}

.section-container-bottom-left {
  display: flex;
  align-items: center;
  max-width: 500px;

  justify-content: center;
  padding: 40px 40px 40px 90px;
}

.section-container-bottom-title {
}

.section-container-bottom-title p {
  font-family: PolySans;
  font-size: 32px;
  font-weight: 600;
  line-height: 38.72px;
  letter-spacing: -0.01em;
  text-align: left;
}

.downloadlink2 img {
  width: 20;
}

.sectionearn {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-top: 50px;
  background-color: #000;
}

.sectionearn-container {
  max-width: 1040px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 70px;
}

.sectionearn-left {
  width: 100%;
  display: grid;
  grid-gap: 20px;
  padding-bottom: 60px;
}

.sectionearn-left h1 {
  color: #fff;
  font-family: PolySans;
  font-size: 72px;
  font-weight: 600;
  line-height: 69.12px;
  letter-spacing: -0.02em;
  text-align: left;
}

.sectionearn-left p {
  font-family: PolySans;
  font-size: 20px;
  font-weight: 300;
  line-height: 31px;
  text-align: left;
  color: #fff;
  margin-top: 15px;
}

.navbar-downloadspace2 {
  display: flex;
  gap: 10px;
}

.downloadlink2 {
  background-color: #fff;
  padding: 10px 18px;
  gap: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  gap: 5px;
  color: #1f1f1f;
  text-decoration: none;
  font-family: PolySans;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
}

.downloadlink2 img {
  width: 20;
}

.sectionearn-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 20px;
}

.sectionearn-right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.section-container-live {
  background-color: #7b5fff;
  border-radius: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-end;
  justify-content: center;
  height: 477px;
}

.section-container-live-left {
  display: flex;
  align-items: center;
  max-width: 500px;

  justify-content: center;
  padding: 40px 40px 40px 90px;
}

.section-container-live-title h1 {
  font-family: PolySans;
  font-size: 46px;
  font-weight: 600;
  line-height: 52.8px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #fff;
}

.section-container-live-title p {
  font-family: PolySans;
  font-size: 20px;
  font-weight: 300;
  line-height: 31px;
  text-align: left;
  color: #fff;
  margin-bottom: 20px;
  margin-top: 20px;
}

.sectionn {
  margin-top: 80px;
}

@media only screen and (max-width: 500px) {
  .sectionearn {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 0px;
    background-color: #000;
  }

  .sectionearn-container {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    gap: 0px !important;
  }

  .sectionearn-left {
    width: 100%;
    display: grid;
  }

  .sectionearn-left h1 {
    color: #fff;
    font-family: PolySans;
    font-size: 56px;
    font-weight: 600;
    line-height: 61.04px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  .sectionearn-left p {
    font-family: PolySans;
    font-size: 20px;
    font-weight: 300;
    line-height: 31px;
    text-align: left;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .navbar-downloadspace2 {
    display: grid;
    flex-direction: column;
    gap: 10px;
  }

  .downloadlink2 {
    background-color: #fff;
    width: 100%;
    padding: 10px 18px;
    gap: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    gap: 5px;
    color: #1f1f1f;
    text-decoration: none;
    font-family: PolySans;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
  }

  .downloadlink2 img {
    width: 20px;
  }

  .sectionearn-title {
    display: grid;
    width: 100%;
    flex-direction: column;
    gap: 10px;
  }

  .sectionearn-right {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .headerimg {
    width: 100%;
  }

  .headerimg img {
    width: 100%;
    max-width: 356px;
    object-fit: contain;
    object-position: center;
  }
}

@media only screen and (max-width: 500px) {
  .section {
    width: 100%;
    padding-top: 20px;
  }
  .section-container {
    margin: 0 auto;
    padding: 10px 16px 16px 16px;
  }
  .section-container-middle {
    flex-direction: column;
    margin-top: 30px;
  }

  .section-container-top h1 {
    font-family: PolySans;
    font-size: 44px;
    font-weight: 600;
    line-height: 55.2px;
    letter-spacing: -0.01em;
    text-align: center;
    margin-bottom: 18px;
  }

  .section-container-bottom {
    margin-top: 28px;
    width: 100%;
    background-color: #f8f8f8;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: max-content;
  }

  .section-container-bottom-left {
    display: flex;
    align-items: center;

    justify-content: center;
    max-width: 500px;
    padding: 20px 16px 20px 16px;
  }

  .section-container-bottom-title {
  }

  .section-container-bottom-title p {
    font-family: PolySans;
    font-size: 28px;
    font-weight: 600;
    line-height: 33.88px;
    letter-spacing: -0.01em;
    text-align: left;
  }

  .section-container-bottom-right {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navbar-downloadspace3 {
    display: grid;
    grid-gap: 10px;
    margin-top: 20px;
  }

  .downloadlink3 {
    margin-top: 0px;
    background-color: #1f1f1f;
    padding: 10px 18px;
    gap: 3px;
    display: flex;
    height: 52px;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    gap: 5px;
    color: #fff;
    text-decoration: none;
    font-family: PolySans;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
  }

  .downloadlink3 img {
    width: 20px;
  }

  .dims1,
  .dims {
    border-radius: 32px;
  }

  .dimss {
    width: 100%;
    height: 306px;
    object-fit: cover;
    border-radius: 32px;
  }

  .headerrimg {
    display: none;
  }

  .section-container-live {
    margin-top: 28px;
    width: 100%;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: max-content;
  }

  .section-container-live-left {
    display: flex;
    align-items: center;

    justify-content: center;
    max-width: 500px;
    padding: 16px;
  }

  .section-container-live-title h1 {
    font-family: PolySans;
    font-size: 36px;
    font-weight: 600;
    line-height: 39.6px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  .section-container-live-right {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hdimg {
    display: inline;
  }

  .hdimg img {
    display: grid;
    width: 100%;
    max-width: 306px;
    object-fit: cover;
  }

  .sectionn {
    margin-top: 20px;
  }
}
