@font-face {
  font-family: "PolySans";
  src: url("./assets/fonts/polysans_bulky.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "PolySans";
  src: url("./assets/fonts/polysans_median.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "PolySans";
  src: url("./assets/fonts/polysans_neutral.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "PolySans";
  src: url("./assets/fonts/polysans_slim.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensures padding and borders are included in the element’s width and height */
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "PolySans", sans-serif;
  position: relative;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "PolySans", sans-serif;
}
