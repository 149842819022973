.chooseride {
  width: 100%;
  padding-top: 150px;
}

.chooseride-container {
  max-width: 1040px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 70px;
}

.chooseride-left {
  width: 100%;
  display: grid;
  grid-gap: 20px;
  padding-bottom: 60px;
}

.chooseride-left h1 {
  color: #fff;
  font-family: PolySans;
  font-size: 100px;
  font-weight: 600;
  letter-spacing: -0.02em;
  text-align: left;
  line-height: 96px;
}

.chooseride-left p {
  font-family: PolySans;
  font-size: 20px;
  font-weight: 300;
  line-height: 31px;
  text-align: left;
  color: #fff;
  margin-top: 15px;
}

.navbar-downloadspace2 {
  display: flex;
  gap: 10px;
}

.downloadlink2 {
  background-color: #fff;
  padding: 10px 18px;
  gap: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  gap: 5px;
  color: #1f1f1f;
  text-decoration: none;
  font-family: PolySans;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
}

.downloadlink2 img {
  width: 20;
}

.chooseride-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 20px;
}

.chooseride-right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

@media only screen and (max-width: 500px) {
  .chooseride {
    width: 100%;
    padding-top: 80px;
  }

  .chooseride-container {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    gap: 0px !important;
  }

  .chooseride-left {
    width: 100%;
    display: grid;
  }

  .chooseride-left h1 {
    color: #fff;
    font-family: PolySans;
    font-size: 56px;
    font-weight: 600;
    line-height: 61.04px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  .chooseride-left p {
    font-family: PolySans;
    font-size: 20px;
    font-weight: 300;
    line-height: 31px;
    text-align: left;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .navbar-downloadspace2 {
    display: grid;
    flex-direction: column;
    gap: 10px;
  }

  .downloadlink2 {
    background-color: #fff;
    width: 100%;
    padding: 10px 18px;
    gap: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    gap: 5px;
    color: #1f1f1f;
    text-decoration: none;
    font-family: PolySans;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
  }

  .downloadlink2 img {
    width: 20;
  }

  .chooseride-title {
    display: grid;
    width: 100%;
    flex-direction: column;
    gap: 10px;
  }

  .chooseride-right {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .headerimg {
    width: 100%;
  }

  .headerimg img {
    width: 286px;
    object-fit: contain;
    object-position: center;
  }
}
