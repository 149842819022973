.footer {
  width: 100%;
  padding: 16px;
  background-color: #111;
  margin-top: 100px;
}

.footer-container {
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
  padding: 50px 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-items {
  display: grid;
  gap: 15px;
}

.footer-item {
  display: flex;
  gap: 10px;
}

.footer-item a img {
  width: 24px;
}

.footer-item-link {
  display: grid;
  gap: 20px;
}

.footer-itemheader {
  font-family: PolySans;
  font-size: 16px;
  font-weight: 300;
  line-height: 24.96px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #868686;
  margin-bottom: 10pxc;
}

.footer-item-link a {
  font-family: PolySans;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
  text-decoration: none;
}

.footer-link2 {
  font-family: PolySans;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
}

.footer-item-link2 {
  display: flex;
  gap: 20px;
}

.footer-item-link2 a {
  font-family: PolySans;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
  text-decoration: none;
}

.navbar-downloadspace4 {
  display: flex;
  gap: 20px;
}

.downloadlink4 {
  background-color: #181818;
  padding: 6px 18px 6px 18px;
  gap: 3px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-decoration: none;
}

.downloadlink4 label {
  font-family: PolySans;
  font-size: 16px;
  font-weight: 300;
  line-height: 24.96px;
  text-align: left;
  color: #fff;
  text-decoration: none;
}



@media only screen and (max-width: 500px) {
  .footer {
    width: 100%;
    padding: 16px;
    background-color: #111;
    margin-top: 100px;
  }
  
  .footer-container {
    width: 100%;
    max-width: 1040px;
    margin: 0 auto;
    padding: 50px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;
  }
  
  .footer-items {
    display: grid;
    gap: 15px;
  }
  
  .footer-item {
    display: flex;
    gap: 10px;
  }
  
  .footer-item a img {
    width: 24px;
  }
  
  .footer-item-link {
    display: grid;
    gap: 20px;
  }
  
  .footer-itemheader {
    font-family: PolySans;
    font-size: 16px;
    font-weight: 300;
    line-height: 24.96px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #868686;
    margin-bottom: 10pxc;
  }
  
  .footer-item-link a {
    font-family: PolySans;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #fff;
    text-decoration: none;
  }
  
  .footer-link2 {
    font-family: PolySans;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #fff;
  }
  
  .footer-item-link2 {
    display: flex;
    gap: 20px;
  }
  
  .footer-item-link2 a {
    font-family: PolySans;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #fff;
    text-decoration: none;
  }
  
  .navbar-downloadspace4 {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .navbar-downloadspace4 a {
    color: #fff;
  }
  
  .downloadlink4 {
    background-color: #181818;
    padding: 6px 18px 6px 18px;
    gap: 3px;
    border-radius: 100px;
    opacity: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    text-decoration: none;
  }

  .downloadlink4 img {
    width: 24px;
  }
  
  .downloadlink4 label {
    font-family: PolySans;
    font-size: 16px;
    font-weight: 300;
    line-height: 24.96px;
    text-align: left;
    color: #fff !important;
  }
  
  
}
