/* background-color: #f4b511; */

.navbar {
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.navbar-container {
  width: 100%;
  background-color: #fff;
  max-width: 1040px;
  padding: 15px 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #00000033;

  top: 20px;
  height: 75px;
}

.navbar-logo {
  font-size: 16px;
  line-height: 20px;
  font-weight: 900;
  color: #000;
  text-decoration: none;
}

.navbar-links {
  display: flex;
  gap: 20px;
  align-items: center;
  list-style: none;
}
.navbar-links a {
  text-decoration: none;
  color: #1f1f1f;
  font-family: PolySans;
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
}

.navbarsignup {
  padding: 3px 5px;
  border-radius: 3px;
  background-color: #ff08e2;
  font-size: 0.8em;
  font-weight: 900 !important;
}

.navbar-downloadspace {
  display: flex;
  gap: 10px;
}

.downloadlink {
  background-color: #181818;
  padding: 6px 18px;
  gap: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  gap: 5px;
  color: #fff;
  text-decoration: none;
  font-family: PolySans;
  font-size: 16px;
  font-weight: 300;
  line-height: 24.96px;
  text-align: left;
}

.downloadlink img {
  width: 20;
}

.navbar-mobile {
  display: none;
}

.navbar-mobile button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-mobile button img {
  width: 16px;
  object-fit: contain;
  object-position: center;
}

@media only screen and (max-width: 500px) {
  .navbar-mobile {
    display: grid;
  }

  .navbar {
    padding: 16px;
  }

  .navbar-container {
    width: 100%;
    height: 54px;
    padding: 15px 16px;
    left: 16px;
  }

  .navbar-downloadspace {
    display: none;
  }

  .navbar-links {
    display: none;
  }
}
